<template>
  <base-section id="about-our-product">
    <div class="text-center">
      <v-container>
        <base-img
          :src="require('@/assets/FAVICON-EK-candy.svg')"
          alt="KidiwiServices"
          class="mx-auto"
          max-width="100"
        />
      </v-container>
    </div>
    <base-section-heading :title="$t('aboutOurProduct.mainTitle')" />

    <v-container>
      <v-row>
        <v-col v-for="card in cards" :key="card.title" cols="12" md="4">
          <kdw-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionAboutOurProduct",

  data: () => ({
    cards: [
      {
        // title: 'Augmenter votre chiffre d’affaires',
        title: "aboutOurProduct.item1Title",
        // subtitle: 'Best Productivity',
        subtitle: "aboutOurProduct.item1SubTitle",
        // text: 'Le digital est désormais un outil indispensable pour le développement de toute activité. Complexe, coûteux à mettre en place, il paraît une barrière infranchissable pour beaucoup d’entreprises. Face à ce constat, KidiwiDigital vous propose une solution qui vous offre ce service, cette visibilité numérique en toute sécurité. Allez-y vous constaterez par vous-même.',
        text: "aboutOurProduct.item1Text",
        callout: "01",
      },
      {
        // title: 'Prix abordables',
        // subtitle: 'Special Offers',
        // text: 'Notre motivation est avant tout de vous proposer une solution qui booste le volume de vos ventes. L’abonnement au site est de 10€ HT par mois et vous pouvez le dénoncer à tout moment (chaque mois débuté est facturé). Une commission de 0.01€ HT est facturée à chaque mise en relation. Notre tarification est claire, transparente et surtout largement abordable.',
        title: "aboutOurProduct.item2Title",
        subtitle: "aboutOurProduct.item2SubTitle",
        text: "aboutOurProduct.item2Text",
        callout: "02",
      },
      {
        // title: 'Intérêts majeurs pour le particulier',
        // subtitle: 'Income Flow',
        // text: 'Fini les navigations forcées sur des sites différents pour rechercher un prestataire ou prendre un rdv chez son coiffeur préféré. A partir d’une seule plateforme, vous pouvez contacter des prestataires et organiser vos rdv. Kidiwidigital devient la référence, votre plateforme préférée au quotidien pour vos recherches de prestataires.',
        title: "aboutOurProduct.item3Title",
        subtitle: "aboutOurProduct.item3SubTitle",
        text: "aboutOurProduct.item3Text",
        callout: "03",
      },
    ],
  }),
};
</script>
